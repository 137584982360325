/*"Helvetica Neue", Helvetica, Arial, sans-serif;*/
/*Georgia, "Times New Roman", Times, serif;*/
.container {
  width: 1156px;
  margin-left: 0;
}
@media (max-width: 1199px) {
  .container {
    margin-left: 0;
    width: auto;
  }
}
.row + .row {
  margin-top: 12px;
}
/**
 * Equal height colums by applying a class of .flex-layout to a row.
 */
.row.flex-layout {
  display: flex;
  flex-wrap: wrap;
}
.row.flex-layout:before,
.row.flex-layout:after {
  display: initial;
}
.row.flex-layout > [class*='col-'] {
  display: flex;
}
.row.no-gutter [class*='col-'],
.row.no-gutters [class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 480px) {
  .row.row-xs-vertical-center {
    display: flex;
    align-items: center;
  }
}
.flex-layout-mixin {
  display: flex;
  flex-wrap: wrap;
}
.flex-layout-mixin:before,
.flex-layout-mixin:after {
  display: initial;
}
.flex-layout-mixin > [class*='col-'] {
  display: flex;
}
.no-gutters-mixin [class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}
.btn {
  white-space: normal;
}
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-default .badge,
.btn-primary .badge,
.btn-success .badge,
.btn-info .badge,
.btn-warning .badge,
.btn-danger .badge {
  text-shadow: none;
}
.btn:active,
.btn.active {
  background-image: none;
}
.btn-default {
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #e0e0e0 100%);
  background-image: -o-linear-gradient(top, #ffffff 0%, #e0e0e0 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #e0e0e0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  text-shadow: 0 1px 0 #fff;
  border-color: #ccc;
}
.btn-default:hover,
.btn-default:focus {
  background-color: #e0e0e0;
  background-position: 0 -15px;
}
.btn-default:active,
.btn-default.active {
  background-color: #e0e0e0;
  border-color: #dbdbdb;
}
.btn-default.disabled,
.btn-default:disabled,
.btn-default[disabled] {
  background-color: #e0e0e0;
  background-image: none;
}
.btn-primary {
  background-image: -webkit-linear-gradient(top, #337ab7 0%, #265a88 100%);
  background-image: -o-linear-gradient(top, #337ab7 0%, #265a88 100%);
  background-image: linear-gradient(to bottom, #337ab7 0%, #265a88 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff265a88', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #245580;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #265a88;
  background-position: 0 -15px;
}
.btn-primary:active,
.btn-primary.active {
  background-color: #265a88;
  border-color: #245580;
}
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary[disabled] {
  background-color: #265a88;
  background-image: none;
}
.btn-success {
  background-image: -webkit-linear-gradient(top, #5cb85c 0%, #419641 100%);
  background-image: -o-linear-gradient(top, #5cb85c 0%, #419641 100%);
  background-image: linear-gradient(to bottom, #5cb85c 0%, #419641 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff419641', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #3e8f3e;
}
.btn-success:hover,
.btn-success:focus {
  background-color: #419641;
  background-position: 0 -15px;
}
.btn-success:active,
.btn-success.active {
  background-color: #419641;
  border-color: #3e8f3e;
}
.btn-success.disabled,
.btn-success:disabled,
.btn-success[disabled] {
  background-color: #419641;
  background-image: none;
}
.btn-info {
  background-image: -webkit-linear-gradient(top, #5bc0de 0%, #2aabd2 100%);
  background-image: -o-linear-gradient(top, #5bc0de 0%, #2aabd2 100%);
  background-image: linear-gradient(to bottom, #5bc0de 0%, #2aabd2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2aabd2', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #28a4c9;
}
.btn-info:hover,
.btn-info:focus {
  background-color: #2aabd2;
  background-position: 0 -15px;
}
.btn-info:active,
.btn-info.active {
  background-color: #2aabd2;
  border-color: #28a4c9;
}
.btn-info.disabled,
.btn-info:disabled,
.btn-info[disabled] {
  background-color: #2aabd2;
  background-image: none;
}
.btn-warning {
  background-image: -webkit-linear-gradient(top, #f0ad4e 0%, #eb9316 100%);
  background-image: -o-linear-gradient(top, #f0ad4e 0%, #eb9316 100%);
  background-image: linear-gradient(to bottom, #f0ad4e 0%, #eb9316 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffeb9316', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #e38d13;
}
.btn-warning:hover,
.btn-warning:focus {
  background-color: #eb9316;
  background-position: 0 -15px;
}
.btn-warning:active,
.btn-warning.active {
  background-color: #eb9316;
  border-color: #e38d13;
}
.btn-warning.disabled,
.btn-warning:disabled,
.btn-warning[disabled] {
  background-color: #eb9316;
  background-image: none;
}
.btn-danger {
  background-image: -webkit-linear-gradient(top, #d9534f 0%, #c12e2a 100%);
  background-image: -o-linear-gradient(top, #d9534f 0%, #c12e2a 100%);
  background-image: linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc12e2a', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #b92c28;
}
.btn-danger:hover,
.btn-danger:focus {
  background-color: #c12e2a;
  background-position: 0 -15px;
}
.btn-danger:active,
.btn-danger.active {
  background-color: #c12e2a;
  border-color: #b92c28;
}
.btn-danger.disabled,
.btn-danger:disabled,
.btn-danger[disabled] {
  background-color: #c12e2a;
  background-image: none;
}
.thumbnail,
.img-thumbnail {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background: #005971;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-image: -webkit-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
  background-image: -o-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
  background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
  background-color: #2e6da4;
}
.navbar {
  margin-bottom: 2px;
}
.navbar-static-top + .container {
  margin-top: 0;
}
.navbar-header {
  float: none;
}
.navbar-brand {
  height: auto;
}
#navbar {
  background-color: #006489;
  border-bottom: 6px solid #35b4bd;
}
.navbar-default {
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #f8f8f8 100%);
  background-image: -o-linear-gradient(top, #ffffff 0%, #f8f8f8 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #f8f8f8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff8f8f8', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .active > a {
  background-image: -webkit-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
  background-image: -o-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
  background-image: linear-gradient(to bottom, #dbdbdb 0%, #e2e2e2 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdbdbdb', endColorstr='#ffe2e2e2', GradientType=0);
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
}
.navbar-brand,
.navbar-nav > li > a {
  font-size: 16px;
}
.navbar-brand .caret,
.navbar-nav > li > a .caret {
  color: #fff;
}
.navbar-inverse {
  background-image: -webkit-linear-gradient(top, #3c3c3c 0%, #222222 100%);
  background-image: -o-linear-gradient(top, #3c3c3c 0%, #222222 100%);
  background-image: linear-gradient(to bottom, #3c3c3c 0%, #222222 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .active > a {
  background-image: -webkit-linear-gradient(top, #080808 0%, #0f0f0f 100%);
  background-image: -o-linear-gradient(top, #080808 0%, #0f0f0f 100%);
  background-image: linear-gradient(to bottom, #080808 0%, #0f0f0f 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff080808', endColorstr='#ff0f0f0f', GradientType=0);
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
}
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}
@media (max-width: 991px) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.3);
    background-image: -webkit-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
    background-image: -o-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
    background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
  }
}
.alert {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.alert-success {
  border-color: #b2dba1;
}
.alert-info {
  border-color: #9acfea;
}
.alert-warning {
  border-color: #f5e79e;
}
.alert-danger {
  border-color: #dca7a7;
}
.progress {
  background-image: -webkit-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
}
.progress-bar {
  background-image: -webkit-linear-gradient(top, #337ab7 0%, #286090 100%);
  background-image: -o-linear-gradient(top, #337ab7 0%, #286090 100%);
  background-image: linear-gradient(to bottom, #337ab7 0%, #286090 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff286090', GradientType=0);
}
.progress-bar-success {
  background-image: -webkit-linear-gradient(top, #5cb85c 0%, #449d44 100%);
  background-image: -o-linear-gradient(top, #5cb85c 0%, #449d44 100%);
  background-image: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff449d44', GradientType=0);
}
.progress-bar-info {
  background-image: -webkit-linear-gradient(top, #5bc0de 0%, #31b0d5 100%);
  background-image: -o-linear-gradient(top, #5bc0de 0%, #31b0d5 100%);
  background-image: linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff31b0d5', GradientType=0);
}
.progress-bar-warning {
  background-image: -webkit-linear-gradient(top, #f0ad4e 0%, #ec971f 100%);
  background-image: -o-linear-gradient(top, #f0ad4e 0%, #ec971f 100%);
  background-image: linear-gradient(to bottom, #f0ad4e 0%, #ec971f 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffec971f', GradientType=0);
}
.progress-bar-danger {
  background-image: -webkit-linear-gradient(top, #d9534f 0%, #c9302c 100%);
  background-image: -o-linear-gradient(top, #d9534f 0%, #c9302c 100%);
  background-image: linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc9302c', GradientType=0);
}
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.list-group {
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 #286090;
  background-image: -webkit-linear-gradient(top, #337ab7 0%, #2b669a 100%);
  background-image: -o-linear-gradient(top, #337ab7 0%, #2b669a 100%);
  background-image: linear-gradient(to bottom, #337ab7 0%, #2b669a 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2b669a', GradientType=0);
  border-color: #2b669a;
}
.list-group-item.active .badge,
.list-group-item.active:hover .badge,
.list-group-item.active:focus .badge {
  text-shadow: none;
}
.panel {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.panel-default > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
}
.panel-primary > .panel-heading {
  background-image: -webkit-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
  background-image: -o-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
  background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
}
.panel-success > .panel-heading {
  background-image: -webkit-linear-gradient(top, #dff0d8 0%, #d0e9c6 100%);
  background-image: -o-linear-gradient(top, #dff0d8 0%, #d0e9c6 100%);
  background-image: linear-gradient(to bottom, #dff0d8 0%, #d0e9c6 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffd0e9c6', GradientType=0);
}
.panel-info > .panel-heading {
  background-image: -webkit-linear-gradient(top, #d9edf7 0%, #c4e3f3 100%);
  background-image: -o-linear-gradient(top, #d9edf7 0%, #c4e3f3 100%);
  background-image: linear-gradient(to bottom, #d9edf7 0%, #c4e3f3 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffc4e3f3', GradientType=0);
}
.panel-warning > .panel-heading {
  background-image: -webkit-linear-gradient(top, #fcf8e3 0%, #faf2cc 100%);
  background-image: -o-linear-gradient(top, #fcf8e3 0%, #faf2cc 100%);
  background-image: linear-gradient(to bottom, #fcf8e3 0%, #faf2cc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fffaf2cc', GradientType=0);
}
.panel-danger > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f2dede 0%, #ebcccc 100%);
  background-image: -o-linear-gradient(top, #f2dede 0%, #ebcccc 100%);
  background-image: linear-gradient(to bottom, #f2dede 0%, #ebcccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffebcccc', GradientType=0);
}
.well {
  background-image: -webkit-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #e8e8e8 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8e8e8', endColorstr='#fff5f5f5', GradientType=0);
  border-color: #dcdcdc;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
}
body {
  margin-bottom: 16px;
  padding: 8px;
}
@media (min-width: 1140px) {
  body {
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
}
body > .container {
  margin-top: 16px;
}
body > .container > h1 {
  margin-bottom: -1px;
}
#body .grid_4 {
  -webkit-box-shadow: 1px 1px 5px #333333;
  box-shadow: 1px 1px 5px #333333;
  padding: 25px 30px 70px 30px;
  margin-bottom: 8px;
}
.grid_1,
.grid_2,
.grid_3,
.grid_4,
.grid_5,
.grid_6 {
  display: block;
  float: none;
}
.container_4 {
  padding: 12px 16px;
  margin-bottom: 16px;
}
.aside {
  padding: 21px 0;
  margin-bottom: 16px;
}
/*
    Typography ported over from 2020 
*/
h3 {
  color: #105a79;
}
h5 {
  color: #105a79;
}
ul {
  padding-left: 20px;
}
.rte strong {
  color: #4FB2C4 !important;
  font-weight: normal !important;
}
.sectioninstruc h2,
.instructions h2 {
  text-transform: uppercase;
  color: #4FB2C4;
  font-size: 110%;
  font-weight: bold;
  margin: 1em 0;
}
.sectioninstruc ol,
.instructions ol {
  margin-left: 15px;
  padding-left: 0;
}
.sectioninstruc ol li,
.instructions ol li {
  padding-left: 0;
  margin-bottom: 15px;
}
.white-bg,
.hero,
.container_4,
#body .grid_4,
.aside,
.profiles,
.region,
.overlay,
.type-search input {
  background-color: #fff;
}
.supp-side-nav ul {
  list-style-type: none;
  padding-left: 0;
}
.supp-side-nav.nav.button-nav li {
  font-family: 'proxima_nova_rgregular', Verdana, Helvetica, sans-serif;
  font-weight: 700 !important;
}
.supp-side-nav.nav.button-nav li a {
  background-color: #d4d4d4 !important;
  text-transform: uppercase;
  color: #646464;
  display: block;
}
.supp-side-nav.nav.button-nav li a:hover {
  background-color: #4eb0b9 !important;
}
.supp-side-nav.nav.button-nav li.selected a {
  background-color: #4eb0b9 !important;
}
.supp-side-nav.nav.button-nav li a {
  background-color: #d4d4d4 !important;
  text-transform: uppercase;
}
.supp-side-nav.nav.button-nav li.selected a {
  background-color: #4eb0b9 !important;
}
.supp-side-nav.nav.button-nav li a {
  text-transform: uppercase;
}
.collapsible-nav .language-selection {
  list-style-type: none;
  padding-left: 0;
}
.collapsible-nav .language-selection .language-sel-option {
  margin-bottom: 5px;
}
.collapsible-nav .language-selection .not-link {
  background-color: #005971;
  padding: 15px 20px;
  display: block;
  text-transform: uppercase;
  color: #fff;
}
.collapsible-nav .language-selection .language {
  height: 4rem;
  margin-bottom: 5px;
}
.collapsible-nav .language-selection .language:nth-child(even) {
  padding-right: 0;
}
.collapsible-nav .language-selection .language a {
  display: block;
  vertical-align: middle;
  width: auto;
  min-height: 30px;
  line-height: 1.1em;
  box-sizing: content-box;
  font-weight: 700;
  background-color: #d4d4d4;
  text-transform: uppercase;
  color: #646464;
  border-radius: 3px;
  padding: 15px 20px;
}
.collapsible-nav .language-selection .language a:hover {
  text-decoration: none;
  background-color: #4eb0b9;
  color: #fff;
}
.collapsible-nav .language-selection .language.selected a {
  background-color: #4eb0b9;
  color: #FFFFFF;
}
.button-nav .not-link {
  background-color: #005971;
  padding: 15px 20px;
  display: block;
  text-transform: uppercase;
  color: #fff;
}
.button-nav a,
a.button {
  padding: 15px 20px;
}
.button-nav li,
a.button {
  margin-bottom: 5px;
}
.white,
#body a.button,
.type-select li,
.style-5.dropdown ul.selecting li.first:hover,
.nav.button-nav li a:hover,
.nav.button-nav li.selected a {
  color: #FFFFFF !important;
}
/*
  custom buttons
*/
.btn {
  min-width: 128px;
}
.btn i[class*='glyphicon'] {
  float: right;
  padding-top: 2px;
}
.btn-primary {
  background-image: none;
  border: 0;
  background-color: #7FC7D5;
  text-transform: uppercase;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-primary:hover {
  background-color: #005971;
}
/*
  Custom dropdowns
*/
.dropdown-menu {
  border: 1px solid rgba(79, 198, 96, 0.15);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.175);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.175);
}
.dropdown-menu > li > a:hover,
.dropdown-menu .dropdown-menu > li > a:focus {
  background-image: none;
  background-color: transparent;
  color: #4fb2c4;
}
.open > .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.dropdown-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.3);
  }
}
/***************************


Input forms


*****************************/
.radio input[type="radio"],
input[type="radio"] {
  margin-left: 0;
  position: absolute;
}
.radio input[type="radio"] + label,
input[type="radio"] + label {
  padding-left: 5px;
  padding-right: 40px;
  min-width: inherit;
}
.error-message {
  color: red;
  display: inline-block;
  padding: 0 0 .5em 0;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: left;
  }
}
.terms-container a {
  text-decoration: none;
  color: #333333;
}
.terms-container a:hover {
  color: #414141;
}
/***********************


  Global nav


***********************/
.navbar-default {
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  background-color: inherit;
  border-bottom: 0;
  margin-top: 25px;
  padding-bottom: 2px;
}
.navbar-default .navbar-toggle {
  border: 0;
}
.navbar-default .dropdown-menu li a {
  font-size: 16px;
  padding: 8px 20px;
}
.navbar-default .navbar-nav {
  padding: 0 10px;
}
.navbar-default .navbar-nav > li > a {
  font-weight: normal;
  text-transform: uppercase;
  text-shadow: none;
  color: #FFFFFF;
  transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li:focus,
.navbar-default .navbar-nav > li.dropdown.open {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
}
@media (max-width: 991px) {
  .navbar-default .navbar-nav > li > a:focus,
  .navbar-default .navbar-nav > li:focus,
  .navbar-default .navbar-nav > li.dropdown.open {
    color: #fff;
    background-color: transparent;
  }
  .navbar-default .navbar-nav > li.dropdown.open:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.3);
  }
  .navbar-default .navbar-nav > li.dropdown.open:hover > .dropdown-menu {
    background-color: #006489;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: transparent;
    /* rgba(255, 255, 255, .3); */
  }
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .active > a {
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
}
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .active > a:hover {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #fff;
}
.navbar-default .navbar-collapse.in {
  overflow-y: visible;
}
@media (min-width: 992px) {
  .navbar-nav > li > a {
    padding: 12px 20px;
  }
}
/***********************

     Actual survey question / response views

***********************/
#assessmentarea .dimension_title {
  margin: 0 0 15px 0;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}
#assessmentarea .dimension_title b {
  color: #105a79;
}
#assessmentarea .responses-row {
  border-bottom: 1px solid #E9E8E5;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#assessmentarea .responses-row textarea {
  height: 6em;
}
#assessmentarea .input_footnote {
  float: right;
  display: block;
  margin: 0 15px 5px 0;
  padding: 0;
  font-style: italic;
}
#assessmentarea .qid {
  font-weight: bold;
  padding-right: 1em;
}
#assessmentarea .qtxt {
  padding-bottom: 1em;
}
#assessmentarea td {
  vertical-align: top;
}
#assessmentarea .horizresponseshdr {
  border-bottom: 1px solid #5CCBD3;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
#assessmentarea .horizresponseshdr td {
  width: 100px;
  border-right: 4px solid transparent;
  vertical-align: top;
  text-align: center;
}
#assessmentarea .response-group td {
  width: 100px;
  text-align: center;
}
#assessmentarea .error-message {
  padding-left: 15px;
}
#assessmentarea input + label {
  display: inline-block;
}
@media (max-width: 1200px) {
  #assessmentarea .horizresponseshdr {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  #assessmentarea .response-group {
    display: block;
  }
  #assessmentarea .response-group tbody,
  #assessmentarea .response-group tr,
  #assessmentarea .response-group td {
    display: block;
  }
  #assessmentarea .response-group td {
    padding: 0 0 0 15px;
    text-align: left;
    width: auto;
  }
  #assessmentarea .response-group input[type='radio'] {
    width: 1.2em;
    height: 1.2em;
  }
  #assessmentarea .response-group input[type='radio'] + span {
    padding-left: 10px;
    display: inline-block;
  }
  #assessmentarea .vertresponselayout input[type='radio'] {
    width: 1.2em;
    height: 1.2em;
  }
  #assessmentarea .vertresponselayout input[type='radio'] + span {
    padding-left: 10px;
    display: inline-block;
  }
  #assessmentarea .vertresponselayout input[type='radio'] + label {
    display: inline-block;
    margin-bottom: 10px;
  }
  #assessmentarea .vertresponselayout input[type='radio'] + label img {
    padding-left: 10px;
  }
}
/***********************

      Footer


************************/
.footer-container {
  margin-top: 28px;
}
.footer-container .nav ul {
  float: left;
  width: 100%;
  margin-bottom: 16px;
  list-style-type: none;
  padding-left: 0;
}
.footer-container .nav li {
  float: left;
  padding-right: 16px;
  line-height: 1.5;
}
.footer-container .logo {
  width: 100%;
}
/***********************

      Media queries


************************/
@media (max-width: 992px) {
  .container {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .container {
    margin-left: 0;
  }
  .inputarea td:nth-child(1) {
    min-width: inherit;
  }
  #body .grid_4 {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-right: 15px;
    padding-left: 15px;
  }
  .instructions {
    background-color: #fff;
    padding: 10px;
  }
  #assessmentarea img {
    width: 100%;
    max-width: 100%;
    display: block;
  }
  #assessmentarea .response-group label {
    margin-bottom: 10px;
  }
  #assessmentarea .response-group input[type='radio'] {
    width: 1.3em;
    height: 1.3em;
  }
  #assessmentarea .response-group input[type='radio'] + span {
    padding-left: 25px;
    margin-top: -20px;
    display: block;
  }
  #assessmentarea .vertresponselayout label {
    margin-bottom: 10px;
  }
  #assessmentarea .vertresponselayout input[type='radio'] {
    width: 1.3em;
    height: 1.3em;
  }
  #assessmentarea .vertresponselayout input[type='radio'] + label {
    padding-left: 25px;
    margin-top: -20px;
    display: block;
    vertical-align: top;
  }
  #assessmentarea .vertresponselayout input[type='radio'] + label img {
    width: auto;
    display: inline-block;
    padding-left: 10px;
  }
  #assessmentarea .vertresponselayout input[type='radio'] + label + br {
    display: none;
  }
}
/***********************

      Utility classes


************************/
@media (min-width: 992px) {
  .pull-right-desktop {
    float: right !important;
  }
  .dropdown-menu.pull-right-desktop {
    right: 0;
    left: auto;
  }
}
/***********************

      Homepage


************************/
.aside-wrapper {
  margin-bottom: 16px;
  height: 100%;
  width: 100%;
  float: left;
}
.aside {
  padding: 16px;
}
.aside h2 {
  margin-top: 0;
}
.video-teaser {
  width: 100%;
}
.mainPageImages {
  width: 100%;
  height: 380px;
}
.mainPageImages .main-image-bkg {
  height: 381px;
  max-width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.mainPageImages .caption {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.9);
  margin-left: 23px;
  padding-left: 14px;
  padding-right: 15px;
  padding-top: 14px;
  padding-bottom: 13px;
  position: absolute;
  top: 304px;
  width: auto;
}
.mainPageImages .clickIcon1 {
  /*float: left;*/
  height: 39px;
  margin: auto;
  margin-top: -210px;
  width: 37px;
}
.mainPageImages .hideit {
  display: none;
}
.mainPageImages .caption p {
  color: #085476;
  /*font-family: 'Conv_ProximaNovaAlt-Reg',Verdana,Helvetica,sans-serif;*/
  font-family: "proxima-nova", "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1em;
  margin-left: 3px;
  margin-top: 5px;
}
.mainPageImages .caption h3 {
  color: #085476;
  /*font-family: 'Conv_ProximaNovaAlt-Thin',Verdana,Helvetica,sans-serif;*/
  font-family: "proxima-nova", "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: 0;
}
.mainPageImages .caption a {
  color: #4FB2C4;
}
.aside img {
  padding-bottom: 12px;
}
.mainPageImages .caption .FilmsWatch {
  padding-top: 15px;
}
.video-teaser-img {
  width: 100%;
  height: 15vh;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}
.twitter-container-home {
  height: 250px;
}
@media (max-width: 991px) {
  .twitter-container-home {
    height: 300px;
  }
}
@media (max-width: 991px) {
  .video-teaser-img {
    background-size: 100% auto;
    height: 300px;
  }
}
/* Shan - main-menu stuff begin */
.main-menu > ul {
  position: absolute;
  right: 0px;
  z-index: 200;
}
/* Shan - main-menu stuff end */
/* imagery */
.aside.twitter .header h4 {
  background-image: url(/IMAGES/2014/icons/twitter.png);
  padding-left: 40px;
}
.select li.first {
  background-image: url(/IMAGES/2014/icons/down-chevron.png);
  background-position: right center;
}
.profiles li {
  background-image: url(/IMAGES/2014/icons/grey-chevron.png);
  background-position: right center;
}
.button-nav a {
  background-image: url(/IMAGES/2014/icons/blue-chevron.png);
  background-position: right center;
  background-repeat: no-repeat;
}
.button-nav a:hover,
.button-nav a:focus,
.button-nav a:active,
.button-nav .selected a,
a.button,
a.button:hover,
a.button:focus,
a.button:active,
.keyword-search button {
  background-image: url(/IMAGES/2014/icons/white-chevron.png);
  background-position: right center;
  background-repeat: no-repeat;
}
.index li a {
  background-image: url(/IMAGES/2014/icons/dot.png);
  background-position: right top;
}
.index li a:hover,
.index li a:focus,
.index li a:active,
.index li.selected a {
  background-image: url(/IMAGES/2014/icons/dot.png);
  background-position: left top;
}
.abstract.style-1 *,
.container_4.style-1 h3,
#body div.container_4.style-1 a,
.container_4.style-1 .article strong,
.overview-wrapper #executive-search-overview .header a,
.overview-wrapper #executive-search-overview p,
.header a,
#header .selected a,
.mid-blue,
h1,
h3,
h5,
h5 a,
h3 a,
h3 span,
.profile h4,
#header .nav span,
.article-nav span,
.related li a span,
#body div > a,
dd,
th,
dd a,
li a,
.article .other-articles a span,
.article .profile .position,
strong,
.article.abstract ul li,
#footer .panel div span,
.reports h4 span,
.reports .header div,
.article-list h4 a,
legend,
#footer .contact .first a,
.overlay a,
#footer p a {
  color: #105a79;
}
@media (max-width: 991px) {
  .recomended-process-image {
    width: 100%;
  }
}
.aside-wrapper {
  background: #fff;
}
.instruct-brown span {
  color: #4eb0b9;
}
#site-wrapper {
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  padding-left: 0;
  transition: all 0.5s ease;
}
#site-wrapper.toggled #sidebar-wrapper {
  transform: translate3d(-250px, 0, 0);
  background-color: rgba(245, 245, 245, 0.3);
}
#site-wrapper.toggled #page-content-wrapper {
  margin-right: -250px;
}
#site-wrapper #page-content-wrapper > .container {
  filter: blur(0) brightness(100%);
  transition: filter .5s ease;
}
#site-wrapper.toggled #page-content-wrapper > .container {
  filter: blur(1rem) brightness(50%);
}
#sidebar-wrapper {
  background: transparent;
  height: 100%;
  right: -250px;
  left: inherit;
  -moz-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transform: translate3d(0, 0, 0);
  overflow-x: hidden;
  overflow-y: scroll;
  width: 250px;
  z-index: 1000;
}
#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}
#sidebar-wrapper ul {
  margin-top: 85px;
}
#sidebar-wrapper.navbar-default {
  margin-top: 0;
  padding-bottom: 0;
}
.navbar-static-top {
  z-index: 1100;
}
.navbar-fixed-top {
  padding-top: 25px;
  padding-bottom: 14px;
  margin-top: 0;
  background: #f5f5f5;
}
#sidebar-wrapper .sidebar-nav {
  background: #006489;
  height: 100%;
  margin-top: 62px;
}
#sidebar-wrapper li {
  border-top: 1px solid #fff;
}
#sidebar-wrapper li:last-child {
  border-bottom: 1px solid #fff;
}
#sidebar-wrapper a {
  color: #fff;
  text-transform: uppercase;
  padding: 12px 24px;
}
#sidebar-wrapper a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
#sidebar-wrapper li.open > a {
  background: rgba(0, 0, 0, 0.2);
}
#sidebar-wrapper li.open > .dropdown-menu {
  background: transparent;
  position: relative;
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
  width: 100%;
  float: none;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
#sidebar-wrapper li.open > .dropdown-menu a {
  padding-left: 36px;
  white-space: normal;
}
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
}
#page-content-wrapper {
  /*padding-top: 70px;*/
  width: 100%;
}
@media (max-width: 991px) {
  #page-content-wrapper {
    padding-top: 80px;
  }
}
.row.flex-layout .col-md-4 .aside-wrapper {
  margin-bottom: 16px;
  background: #fff;
  width: 100%;
}
/*-------------------------------*/
/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/
.hamburger {
  background: transparent;
  border: none;
  display: block;
  height: 32px;
  margin-left: 15px;
  position: fixed;
  right: 15px;
  top: 35px;
  width: 32px;
  z-index: 999;
}
.hamburger:hover {
  outline: none;
}
.hamburger:focus {
  outline: none;
}
.hamburger:active {
  outline: none;
}
.hamburger.is-closed:before {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  color: #ffffff;
  content: '';
  display: block;
  font-size: 14px;
  line-height: 32px;
  opacity: 0;
  text-align: center;
  width: 100px;
}
.hamburger.is-closed .hamb-top {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  background-color: #006489;
  top: 5px;
}
.hamburger.is-closed .hamb-middle {
  background-color: #006489;
  margin-top: -2px;
  top: 50%;
}
.hamburger.is-closed .hamb-bottom {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  background-color: #006489;
  bottom: 5px;
}
.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}
.hamburger.is-open .hamb-top {
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transform: rotate(45deg);
  background-color: #006489;
  margin-top: -2px;
  top: 50%;
}
.hamburger.is-open .hamb-middle {
  background-color: #fff;
  display: none;
}
.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transform: rotate(-45deg);
  background-color: #006489;
  margin-top: -2px;
  top: 50%;
}
.hamburger.is-open:before {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  transform: translate3d(0, 0, 0);
  color: #ffffff;
  content: '';
  display: block;
  font-size: 14px;
  line-height: 32px;
  opacity: 0;
  text-align: center;
  width: 100px;
}
.hamburger.is-open:hover before {
  -webkit-transform: translate3d(-100px, 0, 0);
  transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  display: block;
  opacity: 1;
}
.assessment-container .aside-wrapper {
  float: none;
}
/*# sourceMappingURL=bootstrap-theme.css.map */