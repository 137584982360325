
//
// Load core variables and mixins
// --------------------------------------------------

@import "variables.less";
@import "mixins.less";

//
// Grid
//

// container - fluid at mobile breakpoints, fixed otherwise
.container {
    width: @container-large-desktop;
    margin-left: 0;

    @media (max-width: @screen-md-max) {
      margin-left: 0;
      width: auto;
    }
}

.row + .row { 
  margin-top: 12px; // @grid-gutter-width;
}


/**
 * Equal height colums by applying a class of .flex-layout to a row.
 */
.row {
    &.flex-layout {
        .flex-layout-mixin;
    }
}

.row {
    &.no-gutter,
    &.no-gutters {
        .no-gutters-mixin;
    }
}

// Vertical Center columns within a row
.row {
    &.row-xs-vertical-center {
        @media (max-width: 480px) {
            display: flex;
            align-items: center;
        }
    }
}

// Flex Layout
// Apply to a .row to convert to Flex layout
// May require additional local stying to set specific flexbox settings
.flex-layout-mixin {
    display: flex;
    flex-wrap: wrap;

    &:before,
    &:after {
        display: initial;
    }

    > [class*='col-'] {
        display: flex;
    }
}

// No gutters - apply to .row
.no-gutters-mixin {
    [class*='col-'] {
        padding-left: 0;
        padding-right: 0;
    }
}

//
// Buttons
// --------------------------------------------------

// Shan - added this to fix FireFox ~ v35 issue with buttons with right floated glyph icons overflowing
// to subsequent line
.btn  {
  white-space: normal;
}
// Common styles
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  // Reset the shadow
  &:active,
  &.active {
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  .badge {
    text-shadow: none;
  }
}

// Mixin for generating new styles
.btn-styles(@btn-color: #555) {
  #gradient > .vertical(@start-color: @btn-color; @end-color: darken(@btn-color, 12%));
  .reset-filter(); // Disable gradients for IE9 because filter bleeds through rounded corners; see https://github.com/twbs/bootstrap/issues/10620
  background-repeat: repeat-x;
  border-color: darken(@btn-color, 14%);

  &:hover,
  &:focus  {
    background-color: darken(@btn-color, 12%);
    background-position: 0 -15px;
  }

  &:active,
  &.active {
    background-color: darken(@btn-color, 12%);
    border-color: darken(@btn-color, 14%);
  }

  &.disabled,
  &:disabled,
  &[disabled] {
    background-color: darken(@btn-color, 12%);
    background-image: none;
  }
}

// Common styles
.btn {
  // Remove the gradient for the pressed/active state
  &:active,
  &.active {
    background-image: none;
  }
}

// Apply the mixin to the buttons
.btn-default { .btn-styles(@btn-default-bg); text-shadow: 0 1px 0 #fff; border-color: #ccc; }
.btn-primary { .btn-styles(@btn-primary-bg); }
.btn-success { .btn-styles(@btn-success-bg); }
.btn-info    { .btn-styles(@btn-info-bg); }
.btn-warning { .btn-styles(@btn-warning-bg); }
.btn-danger  { .btn-styles(@btn-danger-bg); }


//
// Images
// --------------------------------------------------

.thumbnail,
.img-thumbnail {
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}


//
// Dropdowns
// --------------------------------------------------

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background: #005971;
  // #gradient > .vertical(@start-color: @dropdown-link-hover-bg; @end-color: darken(@dropdown-link-hover-bg, 5%));
  // background-color: darken(@dropdown-link-hover-bg, 5%);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
  background-color: darken(@dropdown-link-active-bg, 5%);
}


//
// Navbar
// --------------------------------------------------
.navbar {
  margin-bottom: 2px;
}

.navbar-static-top+.container {
  margin-top: 0;
}

.navbar-header { 
  float: none;
}
.navbar-brand { 
  height: auto;
}
#navbar { 
  background-color: #006489;  
  border-bottom: 6px solid #35b4bd;
}

// Default navbar
.navbar-default {
  #gradient > .vertical(@start-color: lighten(@navbar-default-bg, 10%); @end-color: @navbar-default-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered
  border-radius: @navbar-border-radius;
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    #gradient > .vertical(@start-color: darken(@navbar-default-link-active-bg, 5%); @end-color: darken(@navbar-default-link-active-bg, 2%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.075));
  }
}
.navbar-brand,
.navbar-nav > li > a {
  font-size: @font-size-base;

  .caret { 
    color: #fff;
  }
}

// Inverted navbar
.navbar-inverse {
  #gradient > .vertical(@start-color: lighten(@navbar-inverse-bg, 10%); @end-color: @navbar-inverse-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered; see https://github.com/twbs/bootstrap/issues/10257

  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    #gradient > .vertical(@start-color: @navbar-inverse-link-active-bg; @end-color: lighten(@navbar-inverse-link-active-bg, 2.5%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.25));
  }

  .navbar-brand,
  .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }
}

// Undo rounded corners in static and fixed navbars
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}

// Fix active state of dropdown items in collapsed mode
@media (max-width: @grid-float-breakpoint-max) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, .3);
      #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
    }
  }
}


//
// Alerts
// --------------------------------------------------

// Common styles
.alert {
  text-shadow: 0 1px 0 rgba(255,255,255,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.05);
  .box-shadow(@shadow);
}

// Mixin for generating new styles
.alert-styles(@color) {
//  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 7.5%));
  border-color: darken(@color, 15%);
}

// Apply the mixin to the alerts
.alert-success    { .alert-styles(@alert-success-bg); }
.alert-info       { .alert-styles(@alert-info-bg); }
.alert-warning    { .alert-styles(@alert-warning-bg); }
.alert-danger     { .alert-styles(@alert-danger-bg); }


//
// Progress bars
// --------------------------------------------------

// Give the progress background some depth
.progress {
  #gradient > .vertical(@start-color: darken(@progress-bg, 4%); @end-color: @progress-bg)
}

// Mixin for generating new styles
.progress-bar-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 10%));
}

// Apply the mixin to the progress bars
.progress-bar            { .progress-bar-styles(@progress-bar-bg); }
.progress-bar-success    { .progress-bar-styles(@progress-bar-success-bg); }
.progress-bar-info       { .progress-bar-styles(@progress-bar-info-bg); }
.progress-bar-warning    { .progress-bar-styles(@progress-bar-warning-bg); }
.progress-bar-danger     { .progress-bar-styles(@progress-bar-danger-bg); }

// Reset the striped class because our mixins don't do multiple gradients and
// the above custom styles override the new `.progress-bar-striped` in v3.2.0.
.progress-bar-striped {
  #gradient > .striped();
}


//
// List groups
// --------------------------------------------------

.list-group {
  border-radius: @border-radius-base;
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 darken(@list-group-active-bg, 10%);
  #gradient > .vertical(@start-color: @list-group-active-bg; @end-color: darken(@list-group-active-bg, 7.5%));
  border-color: darken(@list-group-active-border, 7.5%);

  .badge {
    text-shadow: none;
  }
}


//
// Panels
// --------------------------------------------------

// Common styles
.panel {
  .box-shadow(0 1px 2px rgba(0,0,0,.05));
}

// Mixin for generating new styles
.panel-heading-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 5%));
}

// Apply the mixin to the panel headings only
.panel-default > .panel-heading   { .panel-heading-styles(@panel-default-heading-bg); }
.panel-primary > .panel-heading   { .panel-heading-styles(@panel-primary-heading-bg); }
.panel-success > .panel-heading   { .panel-heading-styles(@panel-success-heading-bg); }
.panel-info > .panel-heading      { .panel-heading-styles(@panel-info-heading-bg); }
.panel-warning > .panel-heading   { .panel-heading-styles(@panel-warning-heading-bg); }
.panel-danger > .panel-heading    { .panel-heading-styles(@panel-danger-heading-bg); }


//
// Wells
// --------------------------------------------------

.well {
  #gradient > .vertical(@start-color: darken(@well-bg, 5%); @end-color: @well-bg);
  border-color: darken(@well-bg, 10%);
  @shadow: inset 0 1px 3px rgba(0,0,0,.05), 0 1px 0 rgba(255,255,255,.1);
  .box-shadow(@shadow);
}

//
// 2020 styles
//
body { 

  @media (min-width: 1140px) { 
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
  
  margin-bottom: @grid-gutter-width; 
  padding: 8px;
}

body >.container {
  margin-top: @grid-gutter-width;

  &>h1 {
    margin-bottom: -1px;
  }
}
#body .grid_4 {
  .box-shadow(1px 1px 5px #333333);
  padding: 25px 30px 70px 30px;
  margin-bottom: 8px;
}

.grid_1, .grid_2, .grid_3, .grid_4, .grid_5, .grid_6 {
  display: block;
  float: none;
}

.container_4 {
  // float: left; 
  padding: 12px 16px;
  margin-bottom: 16px;

  // .header {
    // margin-top: 1em;
    // margin-bottom: 1.5em;
  // }
}

.aside {
  padding: 21px 0;
  margin-bottom: @font-size-base;
}

/*
    Typography ported over from 2020 
*/
h3 {
  color:#105a79;
}
h5 {
  color: #105a79;
}

ul { 
  padding-left: 20px;
}
.rte strong {
  color: #4FB2C4 !important;
  font-weight: normal !important;
}

.sectioninstruc, .instructions {
  h2 {
    text-transform: uppercase;
    color: #4FB2C4;
    font-size: 110%;
    font-weight: bold;
    margin: 1em 0;
  }
  ol {
    margin-left: 15px;
    padding-left: 0;

    li {
      padding-left: 0;
      margin-bottom: 15px;
    }
  }
}


.white-bg, .hero, .container_4, #body .grid_4, .aside, .profiles, .region, .overlay, .type-search input {
  background-color: #fff;
}

.supp-side-nav ul { list-style-type: none;padding-left: 0; }
.supp-side-nav.nav.button-nav li { font-family: 'proxima_nova_rgregular', Verdana, Helvetica, sans-serif; font-weight: 700 !important; }
.supp-side-nav.nav.button-nav li a { background-color: #d4d4d4 !important; text-transform: uppercase;color:#646464;display:block; }
.supp-side-nav.nav.button-nav li a:hover { background-color: #4eb0b9 !important; }
.supp-side-nav.nav.button-nav li.selected a { background-color: #4eb0b9 !important; }
.supp-side-nav.nav.button-nav li a {
  background-color: #d4d4d4 !important;
  text-transform: uppercase;
}

.supp-side-nav.nav.button-nav li.selected a {
  background-color: #4eb0b9 !important;
}
.supp-side-nav.nav.button-nav li a {
  text-transform: uppercase;
}

.collapsible-nav {

  .language-selection {
    list-style-type: none;
    padding-left: 0;

    .language-sel-option {
      margin-bottom: 5px;
    }

    .not-link {
      background-color: #005971;
      padding: 15px 20px;
      display: block;
      text-transform: uppercase;
      color: #fff;
    }

    .language {
      height: 4rem;
      margin-bottom: 5px;

      &:nth-child(even) {
        padding-right: 0;
      }

    a {
        display: block;
        vertical-align: middle;
        width: auto;
        min-height:30px;
        line-height: 1.1em;
        box-sizing: content-box;
        font-weight: 700;
        background-color: #d4d4d4;
        text-transform: uppercase;
        color:#646464;
        border-radius: 3px;
        padding: 15px 20px;

        &:hover {
          text-decoration: none;
          background-color: #4eb0b9;
          color: #fff;
        }

      }

      &.selected {
        a {
          background-color: #4eb0b9;
          color: #FFFFFF;
        }
      }
    }

  }
}

.button-nav .not-link {
  background-color: #005971;
  padding: 15px 20px;
  display: block;
  text-transform: uppercase;
  color: #fff;
}

.button-nav a, a.button { padding: 15px 20px; }

.button-nav li, a.button { margin-bottom: 5px; }


.white, #body a.button, .type-select li, .style-5.dropdown ul.selecting li.first:hover, .nav.button-nav li a:hover, .nav.button-nav li.selected a {
  color: #FFFFFF !important;
}

/*
  custom buttons
*/
.btn { 
   min-width: 128px; 
//   text-align: left; 
}
.btn i[class*='glyphicon'] {
  float: right;
  padding-top: 2px;
}

.btn-primary {
  background-image:none;
  border:0;
  background-color: #7FC7D5;
  text-transform: uppercase;
  text-shadow: none;
  .box-shadow(none);
}
.btn-primary:hover {
  background-color: #005971;
}

/*
  Custom dropdowns
*/
.dropdown-menu {
  border: 1px solid rgba(79, 198, 96, 0.15);
  .box-shadow(0 2px 2px rgba(0, 0, 0, 0.175));

  &> li > a:hover, .dropdown-menu > li > a:focus {
    background-image: none;
    background-color: transparent;
    // background: #005971;
    color: #4fb2c4;
  }
}

.open > .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
  // -webkit-transition: opacity 500ms ease, visibility 500ms ease;
  // -moz-transition: opacity 500ms ease, visibility 500ms ease;
  // -o-transition: opacity 500ms ease, visibility 500ms ease;
  // transition: opacity 500ms ease, visibility 500ms ease;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: rgba(255, 255, 255, .3);
  }
}

/***************************


Input forms


*****************************/
.radio input[type="radio"], input[type="radio"] {
  margin-left: 0;
  position: absolute;

  &+label {
    padding-left:5px;
    padding-right: 40px;
    min-width:inherit;
  }
}

.error-message {
  color: red;
  display: inline-block;
  padding: 0 0 .5em 0;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: left;
  }
}

.terms-container
{
  a {
    text-decoration: none;
    color: #333333;
    &:hover {
      color: #414141;
    }
  }
}

/***********************


  Global nav


***********************/
.navbar-default {
  background-image: none;
  .box-shadow(none);
  border-radius: 0;
  background-color: inherit;
  border-bottom: 0;
  margin-top:25px;
  padding-bottom: 2px;

  .navbar-toggle { 
    border: 0;
  }
  
  .dropdown-menu li a { 
    font-size: @font-size-base;
    padding: 8px 20px;
  }

  .navbar-nav { 
    padding: 0 10px;
  }
  // .navbar-nav li { 
    // IE hack...might fix time pending
    // overflow:hidden;  
  // }
  .navbar-nav > li > a {
    font-weight: normal;
    text-transform: uppercase;
    text-shadow: none;
    color: #FFFFFF;
    transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
  }
  .navbar-nav > li > a:hover, .navbar-nav > li > a:focus, 
  .navbar-nav > li:focus, .navbar-nav > li.dropdown.open {
    color: #fff;
    background-color: rgba(255, 255, 255, .3);
  }

  @media (max-width: @screen-sm-max) { 
    .navbar-nav > li > a:focus, 
    .navbar-nav > li:focus, .navbar-nav > li.dropdown.open {
      color: #fff;
      background-color: transparent;
    }
    .navbar-nav > li.dropdown.open:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, .3);

      &>.dropdown-menu { 
        background-color: #006489;
      }
    }    
    .navbar-nav .open .dropdown-menu > li > a {
        color: #fff;
        background-color: transparent;
    }
    .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #fff;
        background-color: transparent; /* rgba(255, 255, 255, .3); */
    }        
  }

  .navbar-nav > .open > a, .navbar-nav > .active > a {
    background-image: none;
    .box-shadow(none);
    color: #fff;
    background-color: rgba(255, 255, 255, .3);

    &:hover {
      background: rgba(255, 255, 255, .3);
      color: #fff;
    }
  }

  .navbar-nav > .open > a, .navbar-nav > .open > a:hover, .navbar-nav > .open > a:focus {
    color: #fff;
  }

  .navbar-collapse.in { 
    overflow-y: visible; 
  }
}

@media (min-width: @screen-md-min) {
  .navbar-nav > li > a {
    padding: 12px 20px;
  }
}

/***********************

     Actual survey question / response views

***********************/
#assessmentarea {
  .dimension_title {
    margin: 0 0 15px 0;
    font-weight: bold;
    font-size:12px;
    text-transform:uppercase;

    b { color:#105a79; }
  }

  .responses-row {
    border-bottom: 1px solid #E9E8E5;
    margin-bottom:15px;
    padding-bottom:15px;

    textarea {
      height: 6em;
    }
  }

  .input_footnote {
    float: right;
    display: block;
    margin: 0 15px 5px 0;
    padding: 0;
    font-style: italic;
  }

  .qid {
    font-weight: bold;
    padding-right: 1em;
  }
  .qtxt  {
    padding-bottom: 1em;
  }

  td {
    vertical-align: top;
  }

  .horizresponseshdr {
    border-bottom: 1px solid #5CCBD3;
    margin-bottom: 20px;
    padding-bottom: 15px;

    td {
      width: 100px;
      border-right:4px solid transparent;
      vertical-align: top;
      text-align: center;
    }
  }

  .response-group {
    td {
      width: 100px;
      text-align: center;
    }
  }

  .error-message {
    padding-left: 15px;
  }

  input+label {
    display:inline-block;
  }
}

@media (max-width: @screen-lg-min) {
  #assessmentarea {
    .horizresponseshdr {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .response-group {
      display: block;
      tbody, tr, td {
        display: block;
      }
      td {
        padding: 0 0 0 15px;
        text-align: left;
        width: auto;
        &>label {
//          margin-bottom:0;
        }
      }
      input[type='radio'] {
        width:1.2em;
        height:1.2em;
        &+ span {
          padding-left: 10px;
          display: inline-block;
        }
      }
    }

    .vertresponselayout {
      input[type='radio'] {
        width:1.2em;
        height:1.2em;
        &+ span {
          padding-left: 10px;
          display: inline-block;
        }
        &+ label {
          display: inline-block;
          margin-bottom: 10px;

          img {
            padding-left: 10px;
          }
        }
      }
    }
  }
}


/***********************

      Footer


************************/ 
.footer-container { 
  margin-top: 28px;

  .nav {
    ul { 
      float: left;
      width: 100%;
      margin-bottom: @grid-gutter-width;
      list-style-type: none;
      padding-left: 0;
    }
    li { 
      float: left;
      padding-right: @grid-gutter-width;
      line-height: 1.5;
    }
  }

  .logo { 
    width: 100%;
  } 
}

/***********************

      Media queries


************************/
@media (max-width: @screen-md-min) {
  .container {
    margin-left: 0;
  }
}
@media (max-width: @screen-sm-min) {

  .container {
    margin-left: 0;
  }

  .inputarea td:nth-child(1) {
    min-width: inherit;
  }

  #body .grid_4 {
    .box-shadow(none);
    padding-right: 15px;
    padding-left: 15px;
  }

  .instructions {
    background-color: #fff;
    padding: 10px;
  }

  #assessmentarea {
    img {
      width: 100%;
      max-width: 100%;
      display: block;
    }

    .response-group {
      label {
        margin-bottom: 10px;
      }
      input[type='radio'] {
        width: 1.3em;
        height: 1.3em;

        &+ span {
          padding-left: 25px;
          margin-top:-20px;
          display: block;
        }
      }
    }

    .vertresponselayout {
      label {
        margin-bottom: 10px;
      }
      input[type='radio'] {
        width: 1.3em;
        height: 1.3em;

        & + label {
          padding-left: 25px;
          margin-top: -20px;
          display: block;
          vertical-align: top;

          img {
            width:auto;
            display: inline-block;
            padding-left:10px;
          }
          & + br {
            display: none;
          }
        }
      }
    }
  }
}

/***********************

      Utility classes


************************/
@media (min-width: @screen-md-min) {
  .pull-right-desktop {
    float: right !important;
  }
  .dropdown-menu.pull-right-desktop { 
    right: 0;
    left: auto;
  }
}

/***********************

      Homepage


************************/
.aside-wrapper { 
  margin-bottom: @grid-gutter-width;
  height: 100%;
  width: 100%;
  float: left; 
}
.aside { 
  padding: @grid-gutter-width;

  h2 { 
    margin-top: 0;
  }
}

.video-teaser { 
    width: 100%;
}

.mainPageImages{
	width: 100%;
	height: 380px;
}

.mainPageImages .main-image-bkg { 
    height: 381px;
    max-width: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
}

.mainPageImages .caption{
	background: none repeat scroll 0 0 rgba(255, 255, 255, 0.9);
    margin-left: 23px;
    padding-left: 14px;
    padding-right: 15px;
    padding-top: 14px;
    padding-bottom: 13px;
    position: absolute;
    top: 304px;
    width: auto;
}

.mainPageImages .clickIcon1{
	/*float: left;*/
    height: 39px;
    margin: auto;
    margin-top: -210px;
    width: 37px;
}

.mainPageImages .hideit{
	display: none;
}

.mainPageImages .caption p{
	color: #085476;
	/*font-family: 'Conv_ProximaNovaAlt-Reg',Verdana,Helvetica,sans-serif;*/
	font-family: "proxima-nova","proxima-nova",sans-serif;
	font-style: normal;
	font-weight: 400;
    font-size: 14px;
    line-height: 1.1em;
    margin-left: 3px;
    margin-top: 5px;
}
.mainPageImages .caption h3 {
	color: #085476;
	/*font-family: 'Conv_ProximaNovaAlt-Thin',Verdana,Helvetica,sans-serif;*/
	font-family: "proxima-nova","proxima-nova",sans-serif;
	font-style: normal;
	font-weight: 100;
	font-size: 18px;
	text-transform: uppercase;
	// letter-spacing: -0.2px;
    margin-top: auto;
    margin-bottom: 0;
}
.mainPageImages .caption a {
	color: #4FB2C4;
}

.aside img {
	padding-bottom:12px;
}

.mainPageImages .caption .FilmsWatch{
	padding-top: 15px;
}

.video-teaser-img { 
    width: 100%;
    height: 15vh;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
}

.twitter-container-home {
  height: 250px;
  @media (max-width: @screen-sm-max) {
    height: 300px;
  }
}
@media (max-width: @screen-sm-max) {
  .video-teaser-img { 
    background-size: 100% auto;
    height: 300px;
  }
}


/* Shan - main-menu stuff begin */
.main-menu>ul { position:absolute; right:0px; z-index:200; }
/* Shan - main-menu stuff end */

/* imagery */
.aside.twitter .header h4 { background-image: url(/IMAGES/2014/icons/twitter.png); padding-left: 40px; }

.select li.first { background-image: url(/IMAGES/2014/icons/down-chevron.png); background-position: right center; }

.profiles li { background-image: url(/IMAGES/2014/icons/grey-chevron.png); background-position: right center; }

.button-nav a { background-image: url(/IMAGES/2014/icons/blue-chevron.png); background-position: right center; background-repeat: no-repeat; }

.button-nav a:hover, .button-nav a:focus, .button-nav a:active, .button-nav .selected a, a.button, a.button:hover, a.button:focus, a.button:active, .keyword-search button { background-image: url(/IMAGES/2014/icons/white-chevron.png); background-position: right center; background-repeat: no-repeat; }

.index li a { background-image: url(/IMAGES/2014/icons/dot.png); background-position: right top; }

.index li a:hover, .index li a:focus, .index li a:active, .index li.selected a { background-image: url(/IMAGES/2014/icons/dot.png); background-position: left top; }

.abstract.style-1 *, .container_4.style-1 h3, #body div.container_4.style-1 a, .container_4.style-1 .article strong, .overview-wrapper #executive-search-overview .header a, .overview-wrapper #executive-search-overview p, .header a, #header .selected a, .mid-blue, h1, h3, h5, h5 a, h3 a, h3 span, .profile h4, #header .nav span, .article-nav span, .related li a span, #body div > a, dd, th, dd a, li a, .article .other-articles a span, .article .profile .position, strong, .article.abstract ul li, #footer .panel div span, .reports h4 span, .reports .header div, .article-list h4 a, legend, #footer .contact .first a, .overlay a, #footer p a {
    color: #105a79;
}

@media (max-width: @screen-sm-max) {
  .recomended-process-image {
    width: 100%;
  }
}
.aside-wrapper { 
  background: #fff;
}

.instruct-brown span { 
  color: #4eb0b9;
}

#site-wrapper {
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  padding-left: 0;
  transition: all 0.5s ease;
}
#site-wrapper.toggled #sidebar-wrapper {
  transform: translate3d(-250px, 0, 0);
  background-color: rgba(245, 245, 245, .3);
}
#site-wrapper.toggled #page-content-wrapper {
  margin-right: -250px;
}
#site-wrapper #page-content-wrapper>.container {
  filter: blur(0) brightness(100%);
  transition: filter .5s ease;
}
#site-wrapper.toggled #page-content-wrapper>.container {
  filter: blur(1rem) brightness(50%);
}
#sidebar-wrapper {
  background: transparent;
  height: 100%;
  right: -250px;
  left: inherit;
  -moz-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transform: translate3d(0, 0, 0);

  overflow-x: hidden;
  overflow-y: scroll;
  width: 250px;
  z-index: 1000;
}
#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}
#sidebar-wrapper ul {
    margin-top: 85px;
}
#sidebar-wrapper.navbar-default { 
    margin-top: 0;
    padding-bottom: 0;
}
.navbar-static-top { 
    z-index: 1100;
}
.navbar-fixed-top { 
    padding-top: 25px;
    // padding-left: 8px;
    padding-bottom: 14px;
    margin-top: 0;
    background: #f5f5f5;
}
#sidebar-wrapper .sidebar-nav { 
  background: #006489;  
  height: 100%;
  margin-top: 62px;

}
#sidebar-wrapper li { 
    border-top: 1px solid #fff;
}
#sidebar-wrapper li:last-child { 
    border-bottom: 1px solid #fff;
}
#sidebar-wrapper a { 
    color: #fff;
    text-transform: uppercase;
    padding: 12px 24px;
}
#sidebar-wrapper a:hover { 
    background-color: rgba(0, 0, 0, 0.2) 
}
#sidebar-wrapper li.open > a {
    background: rgba(0, 0, 0, .2);
}
#sidebar-wrapper li.open > .dropdown-menu {
    background: transparent;
    position: relative;
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
    width: 100%;
    float:none;
    margin-bottom:0;
    padding-bottom: 0;
    border-bottom: 0;
}
#sidebar-wrapper li.open > .dropdown-menu a {
    padding-left: 36px;
    white-space: normal;
}
.nav > li > a:hover, .nav > li > a:focus {
    background-color: transparent;
}
#page-content-wrapper {
  /*padding-top: 70px;*/
  width: 100%;
}
@media (max-width: 991px) {
    #page-content-wrapper {
      padding-top: 80px;
    }
}

.row.flex-layout .col-md-4 .aside-wrapper { 
    margin-bottom: 16px;
    background: #fff;
    width: 100%;
}
 
/*-------------------------------*/

/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/
.hamburger {
  background: transparent;
  border: none;
  display: block;
  height: 32px;
  margin-left: 15px;
  position: fixed;
  right: 15px;
  top: 35px;
  width: 32px;
  z-index: 999;
}
.hamburger:hover {
  outline: none;
}
.hamburger:focus {
  outline: none;
}
.hamburger:active {
  outline: none;
}
.hamburger.is-closed:before {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  color: #ffffff;
  content: '';
  display: block;
  font-size: 14px;
  line-height: 32px;
  opacity: 0;
  text-align: center;
  width: 100px;
}
.hamburger.is-closed .hamb-top {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  background-color: #006489; // rgba(255, 255, 255, 0.7);
  top: 5px;
}
.hamburger.is-closed .hamb-middle {
  background-color: #006489; // rgba(255, 255, 255, 0.7);
  margin-top: -2px;
  top: 50%;
}
.hamburger.is-closed .hamb-bottom {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  background-color: #006489; // rgba(255, 255, 255, 0.7);
  bottom: 5px;
}
.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}
.hamburger.is-open .hamb-top {
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transform: rotate(45deg);
  background-color: #006489;
  margin-top: -2px;
  top: 50%;
}
.hamburger.is-open .hamb-middle {
  background-color: #fff;
  display: none;
}
.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  transform: rotate(-45deg);
  background-color: #006489;
  margin-top: -2px;
  top: 50%;
}
.hamburger.is-open:before {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  transform: translate3d(0, 0, 0);
  color: #ffffff;
  content: '';
  display: block;
  font-size: 14px;
  line-height: 32px;
  opacity: 0;
  text-align: center;
  width: 100px;
}
.hamburger.is-open:hover before {
  -webkit-transform: translate3d(-100px, 0, 0);
  transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  display: block;
  opacity: 1;
}

.assessment-container .aside-wrapper { 
  float: none;
}